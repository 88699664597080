import { createContext, ReactNode, useContext, useMemo, useState } from "react";

import assert from "assert";

import { prettyMonthDayYear } from "shared/lib/utils";
import { isRequestOpen } from "shared/services/request.service";

import { CardProps } from "../components/vehicles/tables/Card";
import { RequestCardType } from "../components/vehicles/tables/RequestHistoryCard";
import {
  VehicleCardType,
  VehicleRowRequests,
  VehicleRowType,
} from "../components/vehicles/tables/types";

import { useFleets } from "./FleetProvider";
import { useVehicles } from "./VehicleProvider";

const CardRowContext = createContext<{
  openRequest: VehicleRowRequests | null;
  isSelected: boolean;
  handleSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  roDetailsUrl: string | null;
  vehicleSettingsUrl: string;
  startServiceUrl: string;
  cardProps: CardProps;
  isFromVehiclesPage: boolean;
  isFromHistoryPage: boolean;
  isHovered: boolean;
  setIsHovered: (isHovered: boolean) => void;
}>({
  openRequest: null,
  isSelected: false,
  handleSelected: () => void 0,
  roDetailsUrl: null,
  vehicleSettingsUrl: "",
  startServiceUrl: "",
  cardProps: {
    primaryText: "",
    secondaryText: "",
    tertiaryText: "",
    imageUrl: "",
    vehicleId: null,
    altText: "",
    actionButtons: <></>,
    displayCheckbox: false,
    displayNotification: false,
  },
  isFromVehiclesPage: false,
  isFromHistoryPage: false,
  isHovered: false,
  setIsHovered: () => void 0,
});

export function useCardRow() {
  return useContext(CardRowContext);
}

export function CardRowProvider({
  fleetVehicle,
  request,
  children,
}: Readonly<{
  fleetVehicle: VehicleRowType | VehicleCardType | undefined | null;
  request?: RequestCardType | null;
  children: ReactNode;
}>) {
  const { currentFleetId: currentQueryParams, currentFleetIdAsNumber } =
    useFleets();
  const [isHovered, setIsHovered] = useState(false);
  const { selectedVehicles, selectVehicle, unselectVehicle } = useVehicles();
  const isSelected = !!selectedVehicles.find(
    (v) => v.vehicleId === fleetVehicle?.vehicleId
  );

  const openRequest =
    fleetVehicle?.requests?.find((request) => {
      if (currentFleetIdAsNumber !== -1) {
        return (
          request.fleetId === currentFleetIdAsNumber &&
          isRequestOpen(request.status ?? "")
        );
      } else {
        return isRequestOpen(request.status ?? "");
      }
    }) ?? null;

  const refRequestId = request?.id ?? openRequest?.id;

  const isFromVehiclesPage = location.pathname.includes("/vehicles");
  const isFromHistoryPage = location.pathname.includes("/history");

  //Vehicle details
  const nickname = fleetVehicle?.carNickname ?? null;
  const vehicleYearMakeModel = `${fleetVehicle?.year} ${fleetVehicle?.make} ${fleetVehicle?.model}`;
  let imageUrl = fleetVehicle?.imageUrl;
  if (
    fleetVehicle &&
    "customImageUrl" in fleetVehicle &&
    fleetVehicle.customImageUrl
  ) {
    imageUrl = fleetVehicle.customImageUrl;
  }

  //Links
  const roDetailsUrl = refRequestId
    ? `request/${refRequestId}?fleetId=${currentQueryParams}`
    : null;
  const vehicleSettingsUrl = `vehicles/${fleetVehicle?.vehicleId}?fleetId=${currentQueryParams}`;

  // The StartServiceUrl is only needed for insurance or for the vehicles page of the Fleet Portal
  let startServiceUrl = "";
  if (fleetVehicle?.fleetId) {
    const startServiceParams = new URLSearchParams();
    startServiceParams.append("fleetId", fleetVehicle.fleetId.toString());
    startServiceParams.append(
      "vehicleId",
      fleetVehicle?.vehicleId ? fleetVehicle.vehicleId.toString() : ""
    );
    startServiceUrl =
      process.env.NEXT_PUBLIC_PROJECT_NAME === "customers"
        ? `/services?${startServiceParams.toString()}`
        : `claims/new?fleetId=${currentQueryParams}`;
  }

  const cardProps = useMemo(() => {
    const cardPropsValues = {
      primaryText: `Order #${refRequestId}`,
      secondaryText:
        nickname && nickname !== "" ? nickname : vehicleYearMakeModel,
      tertiaryText: prettyMonthDayYear(openRequest?.customerECD),
      defaultTertiaryText: "No ECD",
      imageUrl,
      vehicleId: fleetVehicle?.vehicleId,
      altText: nickname ?? vehicleYearMakeModel,
      actionButtons: <></>,
      displayCheckbox: false,
      displayNotification: false,
    };

    if (isFromVehiclesPage) {
      cardPropsValues.primaryText = cardPropsValues.secondaryText;
      cardPropsValues.secondaryText = vehicleYearMakeModel;
      cardPropsValues.tertiaryText = fleetVehicle?.vin ?? "";
      cardPropsValues.defaultTertiaryText = "No VIN";
    }

    if (isFromHistoryPage) {
      cardPropsValues.tertiaryText = prettyMonthDayYear(
        request?.updatedAt,
        true
      );
      cardPropsValues.defaultTertiaryText = "No ECD";
    }
    return cardPropsValues;
  }, [
    refRequestId,
    nickname,
    vehicleYearMakeModel,
    openRequest,
    isFromHistoryPage,
    isFromVehiclesPage,
    request,
    imageUrl,
    fleetVehicle?.vin,
  ]);

  const value = useMemo(() => {
    const handleSelected = (event: React.ChangeEvent<HTMLInputElement>) => {
      assert(fleetVehicle?.vehicleId, "Vehicle not found");
      assert(fleetVehicle?.fleetId, "Fleet not found");
      if (event.target.checked) {
        selectVehicle({
          vehicleId: fleetVehicle.vehicleId,
          fleetId: fleetVehicle.fleetId,
        });
      } else {
        unselectVehicle(fleetVehicle.vehicleId);
      }
    };
    return {
      openRequest,
      isSelected,
      handleSelected,
      roDetailsUrl,
      vehicleSettingsUrl,
      startServiceUrl,
      cardProps,
      isFromVehiclesPage,
      isFromHistoryPage,
      isHovered,
      setIsHovered,
    };
  }, [
    openRequest,
    isSelected,
    roDetailsUrl,
    vehicleSettingsUrl,
    startServiceUrl,
    cardProps,
    isFromVehiclesPage,
    isFromHistoryPage,
    fleetVehicle?.vehicleId,
    selectVehicle,
    unselectVehicle,
    isHovered,
  ]);

  return (
    <CardRowContext.Provider value={value}>{children}</CardRowContext.Provider>
  );
}
